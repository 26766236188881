<template>
    <div class="relative">
        <div class="w-full absolute block z-30">
            <Header/>
        </div>
        <div class="space-top lbplus-about-banner-container w-full z-25">
            <div class="relative h-full w-full flex justify-center items-start flex-col">
                <img class="absolute top-0 right-0 h-full object-cover z-5 lg:mr-20 2xl:mr-16 z-15" src="../../../assets/images/about-us/lbplus-about-banner.png" alt="About Us Banner">
                <div class="flex flex-col item z-10 max-w-md lg:max-w-md 2xl:max-w-xl p-0 mobileL:p-4 text-left mobileL:mt-8 
                    ml-8 lg:ml-32 2xl:ml-52 md:gap-8 2xl:gap-12 leading-snug">
                    <img class="w-6 hidden mobileL:block mobileL:w-16 xl:w-20 2xl:w-24" src="../../../assets/images/home-page/flower.png" alt="Flower">
                    <h1 class="lbplus-about-banner-title flex justify-start mobileS:text-2xl xs:text-3xl md:text-4xl lg:text-6xl 2xl:text-8xl text-white-1">
                        About Us
                    </h1>
                    <p class="font-montserrat text-sm sm:text-base md:text-lg lg:text-2xl 2xl:text-3xl pr-32 md:pr-32 lg:pr-24 2xl:pr-36 text-white-1">
                        Lay Bare Plus is your destination for all things waxing, skin care, 
                        and brows! With our elite skin treatments, you’ll leave feeling refreshed!
                    </p>
                </div>
            </div>
        </div>
        <div class="relative flex items-center z-20">
            <img class="absolute w-full h-full object-cover" src="../../../assets/images/about-us/lbplus-body-bg.png" alt="Background Image">
            <div class="relative top-0 left-0 w-full h-full flex items-center justify-center flex-col md:py-20 xl:py-24 leading-snug mobileL:leading-normal">
                <p class="text-white-1 text-sm font-montserrat mobileL:font-medium md:text-lg lg:text-xl xl:text-2xl 2xl:text-4xl px-4 w-4/5 mobileL:w-3/6 text-center py-4">
                    Our passion for waxing has gone beyond waxing itself. 
                    Lay Bare Plus believes in the whole customer experience 
                    - from start to finish.
                </p>
            </div>
        </div>
        <div class="relative">
            <img class="absolute inset-0 w-full h-full object-cover" src="../../../assets/images/home-page/lbplus-blogs/signature-bg.png" alt="Background Image">
            <div class="relative flex py-3 mobileL:py-0 text-green-10">
                <div class="flex flex-col justify-center ml-auto text-center items-center mobileL:gap-8 2xl:gap-12 py-8 md:py-24 lg:py-28 2xl:py-36 z-10">
                    <div class="flex-1 font-mermaid mobileL:text-left font-semibold text-base mobileL:text-lg xl:text-2xl 2xl:text-2xl leading-snug">
                        <h2 class="text-4xl mobileL:text-7xl lg:text-9xl 2xl:text-10xl mb-2 mobileL:mb-4">The Plus Factor</h2>
                    </div>
                    <div class="font-montserrat font-semibold mobileL:font-medium gap-2 w-3/5 mobileL:gap-0 leading-snug mobileL:leading-none text-lg mobileL:text-xl lg:text-3xl 2xl:text-5xl">
                        <p class="mb-8">
                            Our indulgent body care range delivers ahead-of-the-trend skin treatments. 
                            From our founding waxing product, the cold jelly wax. 
                            Our range has evolved with pampering services, each one freshly made 
                            using only premium & natural ingredients.
                        </p>
                        <p>
                            We believe in the power of value - getting value for your money, 
                            getting value in your experience, and above all. Valuing oneself.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="relative">
            <div class="relative bg-brown-6  text-pink-11 ">
                <div class="relative pt-6 mobileL:pt-16 pb-4 mobileL:pb-24 lg:pt-20 lg:pb-24 2xl:pt-28 2xl:pb-32 z-20">
                    <div class="text-center mobileL:mb-4">
                        <h2 class="text-3xl md:text-5xl lg:text-6xl 2xl:text-7xl font-mermaid mobileL:pb-6">The Plus Factor</h2>
                    </div>
                    <div class="mx-auto w-3/5 mobileL:w-5/6 2xl:gap-20 font-montserrat md:gap-12 flex mobileL:flex-row flex-col text-xl md:text-xl lg:text-3xl 2xl:text-4xl">
                        <div class="flex-1 text-center flex flex-col items-center pt-4 md:pt-0 2xl:px-6">
                            <h3 class="font-extrabold mb-2 mobileL:mb-10">PAMPERED FROM HEAD-TO-TOE</h3>
                            <p class="text-base md:text-lg lg:text-xl 2xl:text-2xl lg:px-8 -mx-8 mobileL:mx-0">
                                Our approach does not end in hair-removal alone. 
                                You can bid those skin worries away with our new treatments.
                            </p>
                        </div>
                        <div class="flex-1 text-center flex flex-col items-center 2xl:px-6">
                            <h3 class="font-extrabold mb-2 mobileL:mb-12 lg:mb-14 pt-4 md:pt-5 lg:pt-8">FUSS-FREE</h3>
                            <p class="text-base md:text-lg lg:text-xl 2xl:text-2xl lg:px-8 -mx-8 mobileL:mx-0">
                                Our formula has evolved to providing other benefits for your 
                                skin concerns and promises a more comfortable waxing experience.
                            </p>
                        </div>
                        <div class="flex-1 text-center flex flex-col items-center 2xl:px-6">
                            <h3 class="font-extrabold mb-2 mobileL:mb-10 lg:px-8 pt-4 md:pt-0 2xl:px-12">RELAX WHILE YOU RELAX</h3>
                            <p class="text-base md:text-lg lg:text-xl 2xl:text-2xl lg:px-8 -mx-8 mobileL:mx-0">
                                Add an extra layer of relaxation to your treatment in a haven where 
                                you can disconnect from the outside world and reconnect with yourself.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="z-0">
                    <img class="lg:w-52 2xl:w-64 mobileL:w-44 w-20 absolute left-0 bottom-0" src="../../../assets/images/about-us/pandan.png" alt="">
                    <img class="lg:w-40 2xl:w-52 mobileL:w-32 w-16 right-0 mobileL:right-4 absolute bottom-0" src="../../../assets/images/about-us/watermelon.png" alt="">
                    <img class="lg:w-32 2xl:w-36 mobileL:w-16 w-12 -right-1 bottom-16 mobileL:right-6 lg:bottom-36 absolute mobileL:bottom-28 2xl:bottom-48 2xl:right-8" src="../../../assets/images/home-page/lbplus-blogs/watermelon-2.png" alt="">
                    <img class="lg:w-12 2xl:w-20 mobileL:w-10 w-5 right-2 mobileL:right-20 bottom-28 mobileL:bottom-48 lg:bottom-64 absolute transform rotate-270 2xl:right-32 2xl:bottom-80" src="../../../assets/images/about-us/strawberry.png" alt="">
                    <img class="lg:w-12 2xl:w-20 mobileL:w-10 w-5 right-20 mobileL:right-36 lg:right-48 md:bottom-4 bottom-1 absolute 2xl:right-60" src="../../../assets/images/about-us/strawberry.png" alt="">
                    <img class="lg:w-64 2xl:w-80 mobileL:w-52 w-24 right-0 -top-12 mobileL:-top-28 absolute 2xl:-top-36 z-25" src="../../../assets/images/home-page/lbplus-blogs/cucumber cocoa.png" alt="">
                    <img class="lg:w-32 2xl:w-36 mobileL:w-28 w-9 absolute bottom-16 lg:left-0 lg:bottom-48 mobileL:bottom-36 2xl:bottom-52" src="../../../assets/images/about-us/cucumber-3.png" alt="">
                </div>
            </div>
        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
  </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/LaybarePlusFooter.vue'

export default {
    components: {
        Header,
        Footer,
    },
    mounted() {
        this.$gtag.pageview('/laybareplus/about-us')
    },
}
</script>

<style>
.lbplus-about-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #488c7d 0%, #2B6D5F 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .lbplus-about-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

.lbplus-about-banner-title {
    color: #FFF;
    text-align: center;
    font-family: Mermaid;
    font-style: normal;
    font-weight: 600;
}

@media (min-width: 1536px) {
    .lbplus-about-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .lbplus-about-banner-container{
    height:255px;
    }
    .space-top {
    margin-top: 60px;
    }
}
</style>